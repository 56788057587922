<template>
  <!-- 联系方式 -->
  <div>
    <fw-title style="margin-left:12px" titles="联系方式"/>
    <el-row>
      <!-- 销售方 -->
      <el-col class="detailsRow" :offset="3" :span="6">
        <h3>销售方</h3>
        <p>公司名称：{{contact.sales.company_name||'--'}}</p>
        <p>
          联系人：{{contact.sales.name||'--'}}
        </p>
        <p>联系电话：{{contact.sales.mobile||'--'}}</p>
      </el-col>
      <!-- 采购方 -->
      <el-col class="detailsRow" :offset="1" :span="6">
        <h3>
          {{'采购方'}}
          <i
            style="color:blue;margin-left:5px"
            class="el-icon-edit-outline"
            @click="openBuyPeople"
          ></i>
        </h3>
        <p>公司名称：{{contact.buy.company_name||'--'}}</p>
        <p>联系人：{{contact.buy.name||'--'}}</p>
        <p>联系电话：{{contact.buy.mobile||'--'}}</p>
      </el-col>
    </el-row>
    <!-- 采购的弹窗 -->
    <el-dialog title="采购方联系方式" :visible.sync="specsDialogVisible" width="500px">
      <div class="dialognSpecs">
        <el-form size="small" label-position="left" label-width="80px">
          <el-form-item>
            <div slot="label">
              <span style="konggefu"></span>
              公司名称
            </div>
            <span>{{contact.buy.company_name}}</span>
          </el-form-item>
          <el-form-item>
            <div slot="label">
            
              联系人
            </div>
            <el-input maxlength="16" show-word-limit placeholder="请输入联系人" v-model="buyName"></el-input>
          </el-form-item>
          <el-form-item>
            <div slot="label">
              <!-- <span class="konggefu"></span> -->
             
              联系电话
            </div>
            <el-input maxlength="11" show-word-limit placeholder="请输入联系电话" v-model="buyMobile"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <el-row slot="footer">
        <el-col :span="24" align="center">
          <el-button size="small" @click="specsDialogVisible = false">取 消</el-button>
          <el-button size="small" @click="diaoLogYep" type="primary">确 定</el-button>
        </el-col>
      </el-row>
    </el-dialog>
    <!-- 销售方 -->
    <!-- <buyDrawer ref="buyDrawer"/> -->
    <!-- <chosePeople ref="chosePeople"/> -->
    <!-- <buyPeople ref="buyPeople"/> -->
  </div>
</template>

<script>
// import buyDrawer from "./buyDrawer.vue";
// import chosePeople from "@/components/common/chosePeople/chosePeople.vue";
// import buyPeople from "@/components/common/chosePeopleDepart/chosePeople.vue";
export default {
  props:['detailsData'],
  data() {
    return {
      contact: {
        sales: {
          //销售方
          company_name: "",
          name: "",
          mobile: "",
          type: 1 //type=1为销售方
        },
        buy: {
          //采购
          company_name: "",
          name: "",
          mobile: "",
          type: 2 //type=2为采购
        }
      },
      specsDialogVisible: false,
      buyName: "",
      buyMobile: ""
    };
  },
  components: {
    // buyDrawer,
    // chosePeople,
    // buyPeople
  },
  watch: {
      detailsData(val){
           // 销售方
        this.contact.sales.company_name = val.sales.company_name
        this.contact.sales.name = val.sales.name
        this.contact.sales.mobile = val.sales.mobile
        // 采购方
         this.contact.buy.company_name = val.buy.company_name
        this.contact.buy.name = val.buy.name
        this.contact.buy.mobile = val.buy.mobile
      }
       
       


  },
  mounted() {
  
  },
  methods: {
    openSalesDrawer() {
      this.$refs.chosePeople.open(
        {
          titles: "选择联系人",
          choseData: [], // 已选择的数据。
          type: "radio", // 类型为单选radio,checkbox,
          contact_type: "0" //0.只能内部，不能切换 1.内部，2.外部,3 只能外部，并不能切换
        },
        val => {
          console.log(val);
          // this.contact.sales.company_name = val.list.name;
          this.contact.sales.name = val.list.name;
          this.contact.sales.mobile = val.list.mobile;
        }
      );
    },
    openBuyPeople() {
      this.specsDialogVisible = true;
     
    },
    diaoLogYep() {
      // if(!this.buyName && !this.buyMobile){
      //   this.$message.warning('请填写完整信息')
      //   return
      // }
      this.contact.buy.name = this.buyName;
      this.contact.buy.mobile = this.buyMobile;
      this.specsDialogVisible = false;
      this.buyName = ''
      this.buyMobile = ''
    }
  }
};
</script>

<style lang="scss" scoped>
.detailsRow {
  //   width: 500px;
  background-color: #fafafa;
  padding: 10px 20px;
  line-height: 20px;
  position: relative;
  h3,p{
    margin-bottom: 5px;
  }
}
</style>