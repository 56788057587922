var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fw-title',{staticStyle:{"margin-left":"12px"},attrs:{"titles":"商品明细"}}),_c('el-row',[_c('el-col',{staticStyle:{"margin-bottom":"10px"},attrs:{"span":24}},[_c('vxe-table',{ref:"xTable1",staticClass:"mytable-scrollbar",staticStyle:{"margin-left":"30px"},attrs:{"stripe":"","highlight-hover-row":"","size":"small","data":_vm.tabData.order_item_sku}},[_c('vxe-column',{attrs:{"fixed":"left","type":"seq","title":"序号","width":"50","align":"center"}}),_c('vxe-column',{attrs:{"min-width":"400"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("商品信息")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-row',[_c('el-col',{attrs:{"span":7}},[_c('div',[_c('el-image',{staticStyle:{"width":"80px","height":"80px"},attrs:{"src":row && row.cover_url}})],1)]),_c('el-col',{attrs:{"span":14}},[_c('p',{staticClass:"yichu"},[_vm._v(_vm._s(row.app_goods.name||'--'))]),_c('p',[_vm._v("商品编号："+_vm._s(row.serial_number||'--'))]),_c('p',[_vm._v("商品编码："+_vm._s(row.serial_code||'--'))]),_c('p',[_vm._v("平台SPU："+_vm._s(row.spu||'--'))])])],1)]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("商品规格")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s(row.app_goods && row.attr_path||'--'))])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("数量")]),_c('p',[_vm._v("单位")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v(_vm._s('1'))]),_c('el-tag',{attrs:{"size":"small"}},[_vm._v(_vm._s(row.unit||'--'))])]}}])}),_c('vxe-column',{attrs:{"min-width":"100"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("单价")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticStyle:{"font-weight":"bolder"}},[_vm._v("¥"+_vm._s(row.sale_price||'--'))])]}}])}),_c('vxe-column',{attrs:{"min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("优惠金额")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_vm._v("-"+_vm._s(row.pre_price||'--'))])]}}])}),_c('vxe-column',{attrs:{"min-width":"260"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v("应用计划开始时间 ")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-date-picker',{attrs:{"value-format":"yyyy-MM-dd HH:mm","type":"datetime","placeholder":"选择日期时间"},model:{value:(_vm.app_start_at),callback:function ($$v) {_vm.app_start_at=$$v},expression:"app_start_at"}})]}}])}),_c('vxe-column',{attrs:{"fixed":"right","min-width":"160"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('p',[_vm._v("小计")])]},proxy:true},{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',{staticClass:"redstrong"},[_vm._v("¥"+_vm._s(row.sub_amount||'--'))])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }