<template>
    <!-- 公共应用订单 详情 -->
    <div class="centerBox" >
        <fw-header style="padding:10px" titles="提交订单" :headers="{back:true}" />
        <!--  -->
        <!-- 商品明细 -->
        <commodity ref="commodity" :tabData="detailsData"/>
        <!-- 发票信息 -->
        <invoiceInfo ref="invoiceInfo" :detailsData="detailsData.invoice_header"/>
        <!-- 联系方式 -->
        <contact ref="contact" :detailsData="detailsData.contact"/>
        <!-- 支付 -->
        <el-row style="margin-top:100px">
            <el-col align="right" :span="24">
                <span style="margin-right:50px">
                    <span>
                        订单金额：
                        <span
                            style="color:#F74848 ;font-weight:800;font-size:24px"
                        >¥ {{detailsData.order_item_sku && detailsData.order_item_sku[0].sub_amount||'--'}}</span>
                    </span>
                </span>
                <el-button @click="money" type="danger" style="width:150px">提交订单</el-button>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import commodity from "./components/commodity.vue";
import invoiceInfo from "./components/invoiceInfo.vue";
import detailsRemarks from "./components/detailsRemarks.vue";
import contact from "./components/contact.vue";

export default {
  data() {
    return {
      detailsData: {}
    };
  },
  components: {
    commodity,
    invoiceInfo,
    detailsRemarks,
    contact
  },
  created() {
    // let itemSkuId = window.location.href.substr(
    //   window.location.href.lastIndexOf("=") + 1
    // );
    // console.log(itemSkuId);
    this.queryWindow(this.$route.query.id);
    // console.log(window.location.href.substring(window.location.href.indexof('=')+1).toUpperCase())
  },
  methods: {
    // 前台来的订单
    queryWindow(id) {

      let params = {
        order_id: id
      };
      this.$axios
        .get(`/app_shop/order/membersSendOrderShow/${id}`)
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            res.data.pubilcsFlag = 1;
            this.detailsData = res.data;
            console.log(this.detailsData);
          }
        });
    },

    money() {
      // 商品明细
      let order_item_sku = [];
      let obj = {};

      obj.item_sku_id = this.detailsData.order_item_sku[0].id;
      obj.num = this.detailsData.order_item_sku[0].num;
      obj.pre_price = this.detailsData.order_item_sku[0].pre_price;
      obj.app_start_at = this.$refs.commodity.app_start_at;
      order_item_sku.push(obj)
      // 开票信息
      let invoice_header = {};
      let invoiceData = this.$refs.invoiceInfo.formData;
      invoice_header.header_name = this.detailsData.invoice_header.header_name;
      invoice_header.invoice_type = invoiceData.invoice_type;
      invoice_header.company_code = this.detailsData.invoice_header.company_code;
      invoice_header.bank_name = this.detailsData.invoice_header.bank_name;
      invoice_header.bank_account = this.detailsData.invoice_header.bank_account;
      invoice_header.rate = invoiceData.rate;
      invoice_header.register_address = this.detailsData.invoice_header.register_address;
      // 订单备注
      let desc = invoiceData.desc;

      // 联系人
      let contact = this.$refs.contact.contact;

      // 信息
      let seller_company_name = this.detailsData.sallerCompanyInfo.name;
      let seller_company_id = this.detailsData.sallerCompanyInfo.id;
      let seller_company_code = this.detailsData.sallerCompanyInfo.company_code;
      let params = {
        seller_company_name,
        seller_company_id,
        seller_company_code,
        order_item_sku,
        invoice_header,
        desc,
        contact
      };
      console.log(params)
      // return
      this.$axios.postJSON("/app_shop/membersOrder/add", params).then(res => {
        if (res.code == 200) {
          this.cashier(res.data.id);
        }
      });
     
    },
    // 收银台
    cashier(id) {
      this.$router.push({
        path: "/basePlatform/organizeSpace/payMoney",
        query: {
          id: id,
          appStore: true
        }
      });
    }
  }
};
</script>


<style lang="scss" scoped>
.centerBox {
  width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  margin-top: 10px;
  padding: 0 20px;
  box-sizing: border-box;
}
.footers {
  position: fixed;
  bottom: 0;
  width: 100%;
}
</style>