<template>
  <!-- 发票信息 -->
  <div>
    <fw-title style="margin-left:12px" titles="发票信息"/>
    <el-row>
      <el-form
        style="margin-left:30px"
        size="small"
        label-position="left"
        :model="formData"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <!-- 客户名称 -->
        <!-- :disabled="invFlag" -->
        <el-col :span="24">
          <el-form-item label="发票种类" prop="name">
            <el-radio-group v-model="formData.invoice_type">
              <el-radio  :label="1">增值税专业发票</el-radio>
              <el-radio :label="2">增值税普通发票</el-radio>
              
             
            </el-radio-group>
          </el-form-item>
        </el-col>
        <!-- 社会统一信用代码 -->
        <el-col  :span="24">
          <el-form-item label="税率">
            <el-radio-group v-model="formData.rate">
              <el-radio :label="1">1%</el-radio>
              <el-radio :label="3">3%</el-radio>
              <el-radio :label="6">6%</el-radio>
              <el-radio :label="9">9%</el-radio>
              <el-radio :label="13">13%</el-radio>
            </el-radio-group>
            <div  class="detailsRow">
              <p>购买方名称：{{detailsData && detailsData.header_name||'--'}}</p>
              <p>纳税人识别号：{{detailsData && detailsData.company_code||'--'}}</p>
              <p>地址，电话：{{detailsData && detailsData.register_tel||'--'}}</p>
              <p>开户行及编号：{{detailsData && detailsData.bank_account||'--'}}</p>
              <p>发票税率：{{formData.rate}}%</p>
            </div>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <fw-title style="margin-left:12px" titles="订单备注"/>
    <el-row>
      <el-col :span="12">
        <el-input
          style="margin-left:170px"
          type="textarea"
          :rows="4"
          placeholder="请输入内容"
          v-model="formData.desc"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props:['detailsData'],

  data() {
    return {
      formData: {
        //   发票
        invoice_type: 1,
        rate: 1,
        desc: ""
      },
      
      rules: {},
     
      info: {},
      rateStau: true
    };
  },
  created() {},
  mounted() {
     
  },
  methods: {
  
  }
};
</script>

<style lang="scss" scoped>
.detailsRow {
  width: 500px;
  background-color: #fafafa;
  padding: 10px 20px;
  line-height: 20px;
  position: relative;
  p{
    margin-bottom: 5px;
  }
}
::v-deep.el-textarea__inner {
  resize: none;
}
</style>