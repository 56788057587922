<template>
  <div>
    <!-- 订单备注 -->
    <fw-title titles="订单备注" />
    <el-row style="margin-left:30px">
      <el-col :span="24">
        <p>{{ detailsData.desc }}</p>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["detailsData"],
};
</script>

<style lang="scss" scoped>
.detailsRow {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 12px;
  margin-right: 12px;
  background-color: #fafafa;
  padding: 10px 20px;
  line-height: 30px;
  position: relative;
}
</style>
