<template>
    <!-- 商品明细 -->
    <div>
        <fw-title style="margin-left:12px" titles="商品明细"/>
        <el-row>
            <el-col style="margin-bottom:10px" :span="24">
                <vxe-table
                    style="margin-left:30px"
                    
                    class="mytable-scrollbar"
                    stripe
                    highlight-hover-row
                    size="small"
                    ref="xTable1"
                    :data="tabData.order_item_sku"
                >
                    <vxe-column fixed="left" type="seq" title="序号" width="50" align="center"></vxe-column>
                    <!-- 商品信息 -->
                    <vxe-column min-width="400">
                        <template #header>
                            <p>商品信息</p>
                        </template>
                        <template #default="{ row }">
                            <el-row>
                                <el-col :span="7">
                                    <div>
                                        <el-image
                                            style="width: 80px; height: 80px"
                                            :src=" row && row.cover_url"
                                        ></el-image>
                                    </div>
                                </el-col>
                                <el-col :span="14">
                                    <p class="yichu">{{row.app_goods.name||'--'}}</p>
                                    <p>商品编号：{{row.serial_number||'--'}}</p>
                                    <p>商品编码：{{row.serial_code||'--'}}</p>
                                    <p>平台SPU：{{row.spu||'--'}}</p>
                                </el-col>
                            </el-row>
                        </template>
                    </vxe-column>
                    <!-- 规格 -->
                    <vxe-column min-width="160">
                        <template #header>
                            <p>商品规格</p>
                        </template>
                        <template #default="{ row }">
                            <p>{{row.app_goods && row.attr_path||'--'}}</p>
                        </template>
                    </vxe-column>
                    <!-- 数量 -->
                    <vxe-column min-width="160">
                        <template #header>
                            <p>数量</p>
                            <p>单位</p>
                        </template>
                        <template #default="{ row }">
                            <p>{{'1'}}</p>
                            <el-tag size="small">{{row.unit||'--'}}</el-tag>
                        </template>
                    </vxe-column>
                    <!-- 单价 -->
                    <vxe-column min-width="100">
                        <template #header>
                            <p>单价</p>
                        </template>
                        <template #default="{ row }">
                            <p style="font-weight: bolder">¥{{row.sale_price||'--'}}</p>
                        </template>
                    </vxe-column>
                    <!-- 优惠金额 -->
                    <vxe-column min-width="160">
                        <template #header>
                            <p>优惠金额</p>
                        </template>
                        <template #default="{ row }">
                            <p >-{{row.pre_price||'--'}}</p>
                        </template>
                    </vxe-column>
                    <!-- 应用计划开始时间 -->
                    <vxe-column min-width="260">
                        <template #header>
                            <p>
                                <span style="color:red">*</span>应用计划开始时间
                            </p>
                        </template>
                        <template #default="{ row }">
                            <el-date-picker
                                value-format="yyyy-MM-dd HH:mm"
                                v-model="app_start_at"
                                type="datetime"
                                placeholder="选择日期时间"
                            ></el-date-picker>
                        </template>
                    </vxe-column>
                    <!-- 小记 -->
                    <vxe-column fixed="right" min-width="160">
                        <template #header>
                            <p>小计</p>
                        </template>
                        <template #default="{ row }">
                            <div>
                                <p class="redstrong">¥{{row.sub_amount||'--'}}</p>
                            </div>
                        </template>
                    </vxe-column>
                </vxe-table>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
  props: ["tabData"],
  data() {
    return {
      //   tabData: [],
      app_start_at:null
    };
  },
  components: {},
  watch: {
    tabData(val) {
      console.log(val);
    //   row.app_start_at
      this.app_start_at = val.order_item_sku.app_start_at
      //   this.tabData = val.order_item_sku
    }
  },
  created() {
    
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.detailsRow {
  background-color: #fafafa;
  padding: 10px 20px;
  line-height: 20px;
  position: relative;
}
.all {
  padding: 10px 20px;
  padding-left: 50px;
  line-height: 20px;
}
::v-deep.ipt {
  .el-input__inner {
    padding: 0 3px 0 10px;
  }
}
.yichu {
  width: 154px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #333;
  font-size: 14px;
  font-weight: bolder;
//   -webkit-line-clamp:2;/*设置超过为省略号的行数*/
}
.redstrong{
    color: #F26161;
     font-weight: bolder;
}
</style>